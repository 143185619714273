<template>
  <v-autocomplete
    v-model="valgte"
    item-text="Description"
    multiple
    item-value="Value"
    :items="items"
    :label="label"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "wtMultiSelect",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: Array,
    items: Array,
    disabled: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valgte: Array,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.valgte = this.value;
    },
  },
  watch: {
    value: function () {
      this.init();
    },
    valgte: function (newVal, oldVal) {
      this.$emit("input", this.valgte);
    },
  },
};
</script>
