<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="editedItem.ChickenTilgangID"
        item-text="Description"
        item-value="Value"
        :items="ChickenTilgangItems"
        label="Chicken tilgang"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.StillingsTypeID"
        item-text="Description"
        item-value="Value"
        :items="StillingsTypeItems"
        label="Stillingstype"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.JobbTittelID"
        item-text="Description"
        item-value="Value"
        :items="JobbTittelItems"
        label="Jobb title"
      ></v-autocomplete>
      <v-autocomplete
        v-model="editedItem.AvdelingID"
        item-text="Description"
        item-value="Value"
        :items="AvdelingItemsFirmaID"
        label="Avdeling"
      ></v-autocomplete>
      <v-text-field
        v-model="editedItem.Navn"
        label="Navn"
        maxlength="80"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.Tlf1"
        label="Tlf 1"
        maxlength="20"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.Tlf2"
        label="Tlf 2"
        maxlength="20"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.Tlf3"
        label="Tlf 3"
        maxlength="20"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.eMail1"
        label="E-Post 1"
        maxlength="100"
      ></v-text-field>
      <v-text-field
        v-model="editedItem.eMail2"
        label="E-Post 2"
        maxlength="100"
      ></v-text-field>
      <v-textarea
        v-model="editedItem.Notat"
        filled
        label="Notat"
        auto-grow
      ></v-textarea>
      <v-text-field
        v-model="editedItem.Passord"
        label="Passord"
        maxlength="20"
        v-if="showPassword()"
      ></v-text-field>
      <wtMultiSelect
        v-model="editedItem.itemsMoteDeltagere"
        item-text="Description"
        item-value="Value"
        :items="MoteItems"
        label="Møte deltagere"
      ></wtMultiSelect>
    </v-col>
  </v-row>
</template>
<script>
import KeyValue from "../global/KeyValue";
import wtMultiSelect from "../components/wtMultiSelect.vue";

export default {
  extends: KeyValue,
  name: "dlgBruker",
  components: {
    wtMultiSelect,
  },

  model: {
    prop: "editedItemIn",
    event: "input",
  },

  props: {
    keyFirmaID: Number,
    editedItemIn: Object,
    editMode: Number, //v-if="editMode !== -1" - Not in "new"
  },

  computed: {
    editedItem() {
      return this.editedItemIn
    }
  },

  created() {
    this.GetAvdelingCodeFirmaID(this.keyFirmaID);
    this.GetChickenTilgangCode();
    this.GetJobbTittelCode();
    this.GetStillingsTypeCode();
    this.GetMoteCode();
  },

  methods: {
    output: function () {
      // pass back to the parent
      this.$emit("input", this.editedItem);
    },
    showPassword() {
      return (this.editedItem.BrukerID == localStorage.getItem("UserID"));
    }
  },
};
</script>
